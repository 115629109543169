.associations {
    text-align: center;

    img {}

    b {
        color: #051d3f;
    }

    .row {
        margin-top: 50px;
        font-size: 14px;
        display: flex;

        @media (max-width:960px) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        @media (min-width:960px) {
            display: flex;
            gap: 30px;
            align-items: center;
            justify-content: center;
        }
    }
}
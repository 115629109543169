.footer {
    padding: 15px 15px 7px;
    margin-top: auto;

    .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .text {
        align-self: center;
        font-size: 14px;
        color: var(--mantine-color-mblue-1);
        opacity: 50%;
    }
}
.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-width: 1080px;
    margin: 0 auto;
    color: #051d3f;
}

.yellow {
    color: #EAB32D;
}